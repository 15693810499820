import React from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement,
  MDBCarouselCaption,
  MDBProgressBar,
  MDBBtn,
  MDBIcon,
  MDBDropdownDivider,
  MDBProgress,
  MDBContainer,
  MDBBadge,
  MDBFooter,
  MDBCardOverlay,
  MDBSelect,
  MDBSelectOptions,
  MDBSelectOption,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCardFooter,
  MDBDropdownLink,
  MDBModal,
  MDBListGroup,
  MDBListGroupItem,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalContent,
  MDBInput,
  MDBModalBody,
  MDBModalTitle,
} from "mdb-react-ui-kit";
// import { MDBMultiCarousel, MDBMultiCarouselItem } from 'mdb-react-multi-carousel';
import "./Course.css";
import Footer from "./footer";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Certificate from "./Certificate";
import Image from "../../../src/assets/Background.png";
// import Layer from '../../../src/assets/Layer.png'
import Layer from "../../../src/assets/Layerb.png";
import Layer1 from "../../../src/assets/Layer1.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { borderRadius, height } from "@mui/system";
import displayRazorpay from "../../utils/PaymentGateway";
import CashFreePay from "../../utils/CashfreeGateway";
import queryString from "query-string";
import { useLottie } from "lottie-react";
import paymentsuccess from "../../../src/Student/Lotties/green-tick-on-sucess.json";
import paymentfailure from "../../../src/Student/Lotties/oppstryagain.json";
import footerLogo from "../../../src/assets/footer-logo.png";
import image3 from "../../assets/3.png";
import image4 from "../../assets/5.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 800 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function CourseData(props) {
  const [data, setData] = useState([]);
  const [Images, setImages] = useState([]);
  const [suggestedCourseList, setsuggestedCourseList] = useState([]);
  const [mustWatchBooksList, setmustWatchBooksList] = useState([]);
  const UserId = sessionStorage.getItem("userid");
  const GradeId = sessionStorage.getItem("grade");
  const [ordPlaced, setOrdPlaced] = useState(false);
  // const [testUrl, setTestUrl] = useState(null);
  const firstName = sessionStorage.getItem("username");
  const lastName = sessionStorage.getItem("lastName");
  const mobileNumber = sessionStorage.getItem("mobile");
  const [schoolName, setSchoolName] = useState("");
  const SchoolValue = sessionStorage.getItem("schoolName");
  const [options, setOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [show, setShow] = useState(false);
  const history = useHistory();

  const bgimage = {
    backgroundImage: `url(${Image})`,
    // backgroundRepeat: 'Repeat',
    // height: '100vh',
  };

  useEffect(() => {
    //console.log("Checking school name : ",SchoolValue);

    if (
      sessionStorage.getItem("userid") === null ||
      sessionStorage.getItem("userid") === undefined
    ) {
      window.location.href = "/";
    }
    // let qryParams = queryString.parse(props.location.search);
    // if (qryParams.order_id !== null && qryParams.order_id !== undefined) {
    //   captureOrderDetails();
    // }
  }, []);

  useEffect(() => {
    Axios.get(`/user/CourseList/${UserId}`).then((response) => {
      console.log(response.data.recordset, "$$$$$$$$$$$$$$$$$cl");
      setData(response.data.recordset);
    });

    Axios.get(`/user/suggestedCourseList/${UserId}`).then((response) => {
      setsuggestedCourseList(response.data.recordset, "$$$$$$$$$$$$$$$$$scl");
      //console.log(response.data.recordset)
    });

    // Axios.get("/user/SchoolsList").then((response) => {
    //   console.log(`School List (response)`, response.data.recordset);
    //   setOptions(response.data.recordset,);
    // });
  }, [ordPlaced]);

  useEffect(() => {
    Axios.get(`/user/MustWatchBooks/${UserId}`).then((response) => {
      setmustWatchBooksList(response.data.recordset);
      //console.log(response.data.recordset)
    });
  }, []);

  const handleClick = () => {
    if (SchoolValue != null && SchoolValue != "null" && SchoolValue != "") {
      //console.log("storeSName",SchoolValue)
      amifyRegister();
    } else {
      setShow(true);
    }
  };

  const handleIframe = (testUrlLink) => {
    //console.log("coming in iframe")
    window.location.href = testUrlLink;
  };

  const handleSchoolChange = (school) => {
    setSelectedSchool(school);
    setDropdownOpen(false);
  };

  //   function amifyRegister() {

  //    if( SchoolValue === null || SchoolValue === 'null' || SchoolValue === ''){
  //      sessionStorage.setItem('schoolName', schoolName);
  //   }
  //   else{
  //      sessionStorage.setItem('schoolName', SchoolValue);
  //      setSchoolName(SchoolValue);

  //      const payload = {
  //       "student_name": firstName + ' ' + lastName,
  //       "class": GradeId,
  //       "mobile_number": mobileNumber,
  //       "partner_id": "aimify_padho_baho",
  //      "school_name": selectedSchool.SCHOOLNAME
  //    }

  //    console.log("paylaod",payload)
  //     Axios.post('/user/registerAmify', payload)
  //      .then((res) => {
  //         //console.log("res",res)
  //         if(res.status === 200) {
  //           //console.log("coming to if", res.data.result.url)
  //             // setUrl(res.data.response.url)

  //             const data = {
  //               "userId" : sessionStorage.getItem('userid'),
  //               "url" : res.data.result.url,
  //               "schoolName" : selectedSchool.SCHOOLNAME
  //             }
  //             console.log("data",data)

  //           Axios.post('/user/storeTestUrl',data)
  //            .then((resp) => {
  //              // console.log('res',resp)
  //              handleIframe(res.data.result.url)
  //           })
  //           .catch((error) => {
  //             console.error('Error storing test URL:', error);
  //          });
  //       }
  //     })
  //     .catch((error) => {
  //           console.error('Error registering with Amify:', error);
  //         });
  //     }
  //  }

  function amifyRegister() {
    if (!selectedSchool) {
      if (
        SchoolValue === null ||
        SchoolValue === "null" ||
        SchoolValue === ""
      ) {
        sessionStorage.setItem("schoolName", schoolName);
      } else {
        sessionStorage.setItem("schoolName", SchoolValue);
        setSchoolName(SchoolValue);
        // Add your API call here for the selected school
        const payload = {
          student_name: firstName + " " + lastName,
          class: GradeId,
          mobile_number: mobileNumber,
          partner_id: "aimify_padho_baho",
          school_name: SchoolValue,
        };
        Axios.post("/user/registerAmify", payload)
          .then((res) => {
            if (res.status === 200) {
              const data = {
                userId: sessionStorage.getItem("userid"),
                url: res.data.result.url,
                schoolName: SchoolValue,
              };
              Axios.post("/user/storeTestUrl", data)
                .then((resp) => {
                  handleIframe(res.data.result.url);
                })
                .catch((error) => {
                  console.error("Error storing test URL:", error);
                });
            }
          })
          .catch((error) => {
            console.error("Error registering with Amify:", error);
          });
      }
      return;
    }

    // School is selected, proceed with registration
    const payload = {
      student_name: firstName + " " + lastName,
      class: GradeId,
      mobile_number: mobileNumber,
      partner_id: "aimify_padho_baho",
      school_name: selectedSchool.SCHOOLNAME,
    };

    console.log("payload", payload);

    Axios.post("/user/registerAmify", payload)
      .then((res) => {
        if (res.status === 200) {
          const data = {
            userId: sessionStorage.getItem("userid"),
            url: res.data.result.url,
            schoolName: selectedSchool.SCHOOLNAME,
          };

          console.log("data", data);

          Axios.post("/user/storeTestUrl", data)
            .then((resp) => {
              handleIframe(res.data.result.url);
            })
            .catch((error) => {
              console.error("Error storing test URL:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error registering with Amify:", error);
      });
  }

  function TimeOftheDay() {
    var today = new Date();
    var curHr = today.getHours();
    var timeOfDay = "";
    if (curHr < 12) {
      timeOfDay = "Good Morning";
    } else if (curHr < 18) {
      timeOfDay = "Good Afternoon";
    } else {
      timeOfDay = "Good Evening";
    }
    return timeOfDay;
  }

  const SuccessLottie = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: paymentsuccess,

      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };
    const { View } = useLottie(options);
    return View;
  };

  const FailureLottie = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: paymentfailure,

      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const { ViewF } = useLottie(options);
    return ViewF;
  };

  async function paymentSuccess(userId, courseId) {
    const payload = {
      USER_ID: userId,
      COURSE_ID: courseId,
    };
    await Axios.post("/user/Enrollfree", payload)
      .then((resp) => {
        if (resp.status === 200) {
          toast(
            <div
              className="px-2"
              style={{
                height: "150px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                backgroundSize: "cover",
                borderRadius: "14px",
                alignItems: "center",
                lineHeight: "20px",
              }}
              // className="rcorners"
            >
              <SuccessLottie />
              {
                "You have successfully enrolled to this topic!! Happy Learning!!!"
              }
            </div>,
            {
              toastId: "success1",
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
            }
          );
          history.push("/Course");
        } else {
          console.log("Technical Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function captureOrderDetails() {
    let qryParams = queryString.parse(props.location.search);
    if (
      qryParams &&
      qryParams.order_id !== null &&
      qryParams.order_id !== undefined
    ) {
      //console.log('oid-  ' + qryParams.order_id)
      await Axios.get(`/user/response/${qryParams.order_id}`).then(
        (response) => {
          if (
            response.data.result.ordResponse !== null &&
            response.data.result.ordResponse !== undefined
          )
            if (response.data.result.ordResponse.order_status === "PAID") {
              const orderDetails = {
                ORDER_ID: qryParams.order_id,
                USER_ID: UserId,
                COURSE_ID: 0,
                ORDER_AMOUNT: response.data.result.ordResponse.order_amount,
                ORDER_REQUEST: "",
                ORDER_RESPONSE: "",
                PAYMENT_REQUEST: "Retrieving Reponse",
                PAYMENT_RESPONSE: JSON.stringify(
                  response.data.result.ordResponse
                ),
              };
              Axios.post("/user/OrderDetails", orderDetails)
                .then((response) => {
                  if (response.status === 200) {
                    if (response.data.result.message === "Success") {
                      setOrdPlaced(true);
                      notify();
                    } else {
                      toast(
                        <div
                          className="px-2"
                          style={{
                            height: "150px",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "white",
                            backgroundSize: "cover",
                            borderRadius: "14px",
                            alignItems: "center",
                            lineHeight: "20px",
                          }}
                          // className="rcorners"
                        >
                          <FailureLottie />
                          {"Problem with Payment Processing! Please Retry"}
                        </div>,
                        {
                          toastId: "failure",
                          autoClose: 3000,
                          position: toast.POSITION.TOP_CENTER,
                        }
                      );
                    }
                  } else {
                    console.log("Technical Error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              toast(
                <div
                  className="px-2"
                  style={{
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    backgroundSize: "cover",
                    borderRadius: "14px",
                    alignItems: "center",
                    lineHeight: "20px",
                  }}
                  // className="rcorners"
                >
                  <FailureLottie />
                  {"Problem with Payment Processing! Please Retry"}
                </div>,
                {
                  toastId: "failure",
                  autoClose: 3000,
                  position: toast.POSITION.TOP_CENTER,
                }
              );
              //console.log('Technical Error')
            }
        }
      );
    }
  }
  //}

  const notify = (courseTitle) => {
    toast.success(
      <div>
        {`Hey ${sessionStorage.getItem(
          "username"
        )}! You have successfully enrolled for the course. Happy Learning!!`}
      </div>,
      {
        toastId: "success1",
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const notenrolled = () => {
    toast.success(
      <div>{"Enroll to view this course enjoy your learning "}</div>,
      {
        toastId: "success1",
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const navigateTo = async (course, path) => {
    await sessionStorage.setItem("courseObj", JSON.stringify(course));
    history.push(path);
  };

  const enrollPaidCourse = async (course) => {
    let username = sessionStorage.getItem("username");

    let mobile = sessionStorage.getItem("mobile");

    let email = sessionStorage.getItem("email");

    let price = 0;
    //console.log(course)
    if (course !== null) {
      if (course.COURSE_TYPE !== 0) {
        price =
          course.DISCOUNT_PERCENTAGE !== null &&
          course.DISCOUNT_PERCENTAGE !== undefined
            ? Math.round(
                course.ACTUAL_PRICE -
                  (course.ACTUAL_PRICE * course.DISCOUNT_PERCENTAGE) / 100
              )
            : course.ACTUAL_PRICE;
        //console.log('course- ', JSON.stringify(course), price, username, email, mobile, UserId, course.COURSE_ID)
        //displayRazorpay(price, username, email, mobile, UserId, course.COURSE_ID)
        CashFreePay(
          UserId,
          price,
          username,
          email,
          mobile,
          course.COURSE_ID,
          course.COURSE_TITLE
        );
      } else {
        enroll(course);
      }
    }
  };

  const enroll = (props) => {
    if (props.COURSE_TYPE === 0) {
      const payload = {
        USER_ID: UserId,
        COURSE_ID: props.COURSE_ID,
      };
      Axios.post("/user/Enrollfree", payload)
        .then((response) => {
          if (response.status === 200) {
            notify(props.COURSE_TITLE);
          } else {
            console.log("Technical Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (props.COURSE_TYPE === 1) {
      notenrolled();
    }
  };

  return (
    <>
      <div style={bgimage} alt="backgroundimage">
        <div
          style={{
            paddingLeft: "5rem",
            paddingTop: "20px",
            paddingBottom: "20px",
            color: "black", //'#1F594A',
            fontFamily: "Playfair Display",
          }}
        >
          {/* <h1>Welcome {sessionStorage.getItem('username')}!!</h1> */}
        </div>
        <div style={{ paddingLeft: "5rem" }}>
          <MDBRow
            breakpoint="xl"
            style={{
              height: "200px",
              width: "96%",
            }}
          >
            <MDBCarousel style={{ width: "100%" }}>
              <MDBCarouselInner
                style={{ height: "220px", width: "100%", borderRadius: "5px" }}
              >
                <MDBCarouselItem className="active">
                  <MDBCarouselElement
                    className="courseBanner"
                    src={image4}
                    alt="..."
                    height="220px"
                    width="100%"
                  />
                </MDBCarouselItem>
                {/* <MDBCarouselItem>
                  <MDBCarouselElement
                    className="courseBanner"
                    src={image4}
                    alt="..."
                    height="200px"
                    width="100%"
                  />
                </MDBCarouselItem> */}
                {/* <MDBCarouselItem>
                  <MDBCarouselElement
                    className="courseBanner"
                    src="/static/Images/banner_03.png"
                    alt="..."
                    height="200px"
                    width="100%"
                  />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement
                    className="courseBanner"
                    src="/static/Images/banner_07.png"
                    alt="..."
                    height="200px"
                    width="100%"
                  />
                  </MDBCarouselItem> */}
                {/* <MDBCarouselItem>
                      {(SchoolValue != null && SchoolValue != 'null' && SchoolValue != '') ? (
                        <MDBCarouselElement
                          className="courseBanner"
                          src="/static/Images/banner_aimify_test.png"
                          alt="..."
                          height="200px"
                          width="100%"
                          onClick={handleClick}
                        />
                        ) : (
                      <MDBCarouselElement
                        className="courseBanner"
                        src="/static/Images/banner_aimify_register.png"
                        alt="..."
                        height="200px"
                        width="100%"
                        onClick={handleClick}
                      />
                    )}
              </MDBCarouselItem> */}
              </MDBCarouselInner>
            </MDBCarousel>
          </MDBRow>
        </div>
        <div style={{ paddingLeft: "5rem", paddingBottom: "50px" }}>
          <MDBModal staticBackdrop show={show} setShow={setShow} tabIndex="-1">
            <MDBModalDialog size="md-down" centered>
              <MDBModalContent>
                <MDBModalBody>
                  <MDBCol>
                    <div className="d-flex align-items-center justify-content-between">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "28px",
                          color: "#FE3D0B",
                          textAlign: "center",
                        }}
                      >
                        Amify Test
                      </span>
                      <MDBIcon
                        fas
                        icon="times"
                        className="d-flex align-items-end"
                        onClick={() => setShow(false)}
                      />
                    </div>

                    <div
                      style={{
                        fontFamily: "PlayfairDisplay",
                        fontWeight: "bold",
                        fontSize: "18px",
                        padding: "15px",
                        textAlign: "justify",
                      }}
                    >
                      Please Complete the form
                    </div>
                    <form>
                      <MDBInput
                        autoFocus
                        class="borderr"
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                          height: "50px",
                          paddingBottom: "18px",
                          paddingLeft: "7px",
                        }}
                        tabIndex="1"
                        // size="lg"
                        required
                        label="First Name"
                        name="FirstName"
                        value={firstName + " " + lastName}
                      />

                      <MDBInput
                        autoFocus
                        class="borderr"
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                          height: "50px",
                          paddingBottom: "18px",
                          paddingLeft: "7px",
                        }}
                        tabIndex="1"
                        // size="lg"
                        required
                        label="Grade"
                        name="Grade"
                        value={GradeId}
                        className="form-control"
                      />

                      <MDBInput
                        autoFocus
                        class="borderr"
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                          height: "50px",
                          paddingBottom: "18px",
                          paddingLeft: "7px",
                        }}
                        tabIndex="1"
                        // size="lg"
                        required
                        label="Mobile Number"
                        name="Mobile Number"
                        value={mobileNumber}
                        className="form-control"
                      />

                      <MDBInput
                        class="borderr"
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                          height: "50px",
                          paddingBottom: "18px",
                          paddingLeft: "7px",
                        }}
                        tabIndex="1"
                        required
                        readOnly
                        value={
                          selectedSchool
                            ? selectedSchool.SCHOOLNAME
                            : "Select School"
                        }
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        icon="angle-down"
                        iconClassName="dropdown-icon"
                      />
                      {dropdownOpen && (
                        <div>
                          <input
                            type="text"
                            placeholder="Search"
                            style={{
                              width: "100%",
                              padding: "8px 12px",
                              border: "none",
                              borderBottom: "1px solid #ccc",
                              outline: "none",
                            }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          {options
                            .filter((school) =>
                              school.SCHOOLNAME.toLowerCase().includes(
                                searchTerm.toLowerCase()
                              )
                            )
                            .map((school) => (
                              <div
                                key={school.SCHOOLNAME}
                                onClick={() => {
                                  handleSchoolChange(school);
                                }}
                                style={{
                                  padding: "8px 12px",
                                  cursor: "pointer",
                                }}
                              >
                                {school.SCHOOLNAME}
                              </div>
                            ))}
                        </div>
                      )}

                      <br />
                      <div className="d-flex align-items-center justify-content-center">
                        <MDBBtn
                          type="button"
                          size="lg"
                          //className='align-items-center'
                          style={{
                            backgroundColor: "#FE3D0B",
                            Width: "20px",
                            letterSpacing: "1.5px",
                          }}
                          onClick={() => {
                            amifyRegister();
                          }}
                        >
                          Register
                        </MDBBtn>
                        {/* {iframeUrl && (
                                     <iframe src={iframeUrl} title="API URL" onLoad={handleIframeLoad} style={{ display: 'none' }} />
                                  )} */}
                      </div>
                    </form>
                  </MDBCol>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

          {/* <h2>{TimeOftheDay()} {sessionStorage.getItem('username')}!</h2> */}
          {data === null || data === undefined ? (
            <MDBRow
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                fontFamily: "Poppins",
                color: "black",
              }}
            >
              <h2>
                {TimeOftheDay()} {sessionStorage.getItem("username").trim()}! We
                Are Currently Working On Building Up Content For You. Stay
                Tuned.. Please Enroll Into The Life Skills & Amazing Book
                Collection. Happy Learning.
              </h2>
            </MDBRow>
          ) : (
            <>
              <MDBRow
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  fontFamily: "Poppins",
                  color: "black",
                }}
              >
                <h4>
                  {TimeOftheDay()} {sessionStorage.getItem("username").trim()}!
                </h4>
                {data &&
                data.filter((course) => course.TOTAL_SCORE > 0).length > 0 ? (
                  <h4>
                    {" "}
                    Pleasure To Have You Back. Let's Resume Your Learning!
                  </h4>
                ) : (
                  <>
                    <h4>
                      {" "}
                      Pleasure To Have You On Learnsquare. Let's Start Your
                      Learning!
                    </h4>
                    <h2 style={{ color: "#283EF4" }}> Recorded Streams</h2>
                  </>
                )}
              </MDBRow>
              <MDBRow
                style={{
                  paddingBottom: "20px",
                  height: "100%",
                }}
              >
                <Carousel responsive={responsive}>
                  {data &&
                    data.map((course) => (
                      <MDBCol>
                        <MDBCard
                          className="w-105 customizedborder"
                          style={{
                            maxWidth: "580px",
                            height: "100%",
                            fontFamily: "Playfair Display",
                            marginRight: "20px",
                          }}
                          key={"card" + course.COURSE_ID}
                          id={course.COURSE_ID}
                        >
                          {" "}
                          <MDBRow style={{ height: "100%" }}>
                            <MDBCol md="4">
                              <a
                                href="#!"
                                onClick={() => navigateTo(course, "/Course")}
                              >
                                <MDBCardImage
                                  src={
                                    course.COURSE_IMAGE !== "" &&
                                    course.COURSE_IMAGE !== null
                                      ? `/static/Images/${course.COURSE_IMAGE}`
                                      : "/static/Images/Default.png"
                                  }
                                  alt="..."
                                  fluid
                                  style={{
                                    height: "100%",
                                    minWidth: "180px",
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                  }}
                                />
                              </a>
                            </MDBCol>
                            <MDBCol
                              md="8"
                              // className="gradient-custom"
                            >
                              <MDBCardBody style={{ height: "80%" }}>
                                <MDBCardTitle style={{ color: "#000000" }}>
                                  <div class="wrap">
                                    <div class="td" style={{ width: "85%" }}>
                                      <MDBTypography
                                        tag="h5"
                                        variant="h5"
                                        fontFamily="Playfair Display"
                                        style={{
                                          // height : '40%',
                                          color: "black",
                                          fontWeight: "bold",
                                          fontFamily: "poppins",
                                        }}
                                      >
                                        {course.COURSE_TITLE}
                                      </MDBTypography>
                                    </div>
                                    <div class="td" style={{ width: "15%" }}>
                                      {course.TOTAL === course.COMPLETED ? (
                                        <Certificate
                                          CandidateName={sessionStorage.getItem(
                                            "username"
                                          )}
                                          CourseTitle={course.COURSE_TITLE}
                                        />
                                      ) : (
                                        <span></span>
                                      )}
                                    </div>
                                  </div>
                                </MDBCardTitle>
                                <MDBCardText>
                                  <MDBCarousel showControls fade>
                                    <MDBCarouselInner>
                                      <MDBCarouselItem
                                        className="active"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "black",
                                        }}
                                      ></MDBCarouselItem>
                                      <MDBCarouselItem
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "black",
                                        }}
                                      >
                                        <MDBCardBody>
                                          <MDBCarouselCaption
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <p>
                                              {course.TOTAL_AVAILABLE_POINTS -
                                                course.TOTAL_SCORE >
                                              0 ? (
                                                <div>
                                                  You can Earn{" "}
                                                  {course.TOTAL_AVAILABLE_POINTS -
                                                    course.TOTAL_SCORE}{" "}
                                                  Points More, By Completing
                                                  this Course.
                                                </div>
                                              ) : (
                                                <div>
                                                  Congratulations on
                                                  Successfully Completing the
                                                  Course!!! Do Check Out our
                                                  Other Courses and Earn MORE!
                                                </div>
                                              )}
                                            </p>
                                          </MDBCarouselCaption>
                                        </MDBCardBody>
                                      </MDBCarouselItem>
                                      <MDBCarouselItem
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "black",
                                        }}
                                      >
                                        <MDBCardBody>
                                          <MDBCarouselCaption
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <p>
                                              {Math.round(
                                                (course.COMPLETED /
                                                  course.TOTAL) *
                                                  100
                                              ) > 50 ? (
                                                <div>
                                                  {Math.round(
                                                    (course.COMPLETED /
                                                      course.TOTAL) *
                                                      100
                                                  )}
                                                  % of the Course is Completed!!{" "}
                                                  <br />
                                                  Great Going!!
                                                </div>
                                              ) : (
                                                <div>
                                                  {Math.round(
                                                    (course.COMPLETED /
                                                      course.TOTAL) *
                                                      100
                                                  )}
                                                  % of the Course is Completed!!{" "}
                                                  <br /> You Can Do Much More!!{" "}
                                                </div>
                                              )}
                                            </p>
                                          </MDBCarouselCaption>
                                        </MDBCardBody>
                                      </MDBCarouselItem>
                                      <MDBCarouselItem
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "black",
                                        }}
                                      >
                                        <MDBCardBody>
                                          <MDBCarouselCaption
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <p>
                                              {course.LAST_ACTIVITY_DAYS > 0 ? (
                                                <div>
                                                  We missed you since{" "}
                                                  {course.LAST_ACTIVITY_DAYS}{" "}
                                                  {course.LAST_ACTIVITY_DAYS ===
                                                  1 ? (
                                                    <span>day!</span>
                                                  ) : (
                                                    <span>days!</span>
                                                  )}{" "}
                                                </div>
                                              ) : (
                                                <div>
                                                  Appreciate your Prompt
                                                  Learning
                                                </div>
                                              )}
                                            </p>
                                          </MDBCarouselCaption>
                                        </MDBCardBody>
                                      </MDBCarouselItem>
                                    </MDBCarouselInner>
                                  </MDBCarousel>
                                </MDBCardText>
                              </MDBCardBody>

                              <MDBRow
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "20%",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                  }}
                                  className="float-start"
                                >
                                  <MDBIcon
                                    fas
                                    icon="coins"
                                    size="xl"
                                    className="rounded-circle my-3 mx-1 "
                                    style={{ color: "blue" }}
                                  />
                                  {course.TOTAL_SCORE ? course.TOTAL_SCORE : 0}{" "}
                                  COINS EARNED
                                  <a
                                    // href={`/Allcourses?courseId=${user.COURSE_ID}&courseTitle=${user.COURSE_TITLE}`}
                                    href="#!"
                                    onClick={() =>
                                      navigateTo(course, "/Course")
                                    }
                                  >
                                    <MDBBtn
                                      type="button"
                                      size="sm"
                                      className="float-end"
                                      style={{
                                        backgroundColor: "#6255A5",
                                        Width: "20px",
                                        letterSpacing: "1.5px",
                                        marginRight: "2vw",
                                      }}
                                    >
                                      Continue
                                    </MDBBtn>
                                  </a>
                                </div>
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                        </MDBCard>
                      </MDBCol>
                    ))}
                </Carousel>
              </MDBRow>
            </>
          )}
          <MDBDropdownDivider></MDBDropdownDivider>
          {suggestedCourseList && suggestedCourseList.length > 0 ? (
            <MDBRow
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                color: "#1F594A",
                fontFamily: "Poppins",
              }}
            >
              <h2 style={{ color: "#283EF4" }}> Live Streams</h2>
              {/* <h2>
                <span style={{ color: "black" }}>
                  Here Are Our Recommendations For You
                </span>
              </h2> */}
            </MDBRow>
          ) : (
            <></>
          )}
          <MDBRow
            style={{
              paddingBottom: "10px",
            }}
          >
            <Carousel responsive={responsive}>
              {suggestedCourseList &&
                suggestedCourseList.map((course) => (
                  <MDBCol>
                    <MDBCard
                      className="w-105 customizedborderg"
                      style={{
                        width: "580px",

                        fontFamily: "Poppins",
                        marginRight: "20px",
                      }}
                      id={course.COURSE_ID}
                      key={"scard" + course.COURSE_ID}
                    >
                      {" "}
                      <MDBRow className="g-0">
                        <MDBCol md="4">
                          <a
                            href="#!"
                            // onClick={() => navigateTo(course, '/Description')}
                          >
                            <MDBCardImage
                              src={
                                course.COURSE_IMAGE !== "" &&
                                course.COURSE_IMAGE !== null
                                  ? `/static/Images/${course.COURSE_IMAGE}`
                                  : "/static/Images/Default.png"
                              }
                              alt="..."
                              fluid
                              style={{
                                height: "260px",
                                maxWidth: "180px",
                                borderTopLeftRadius: "5px",
                                borderBottomLeftRadius: "5px",
                                borderColor: "gray",
                              }}
                            />
                          </a>
                        </MDBCol>
                        <MDBCol
                          md="8"
                          // className="gradient-custom"
                        >
                          <MDBCardBody>
                            <MDBCardTitle style={{ color: "#000000" }}>
                              <div class="wrap">
                                <div class="td" style={{ width: "100%" }}>
                                  <MDBTypography
                                    tag="h4"
                                    variant="h4"
                                    fontFamily="Playfair Display"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {course.COURSE_TITLE}
                                  </MDBTypography>
                                </div>
                              </div>
                            </MDBCardTitle>
                            <MDBCardText
                              style={{
                                // color: ' #AAAFBB',
                                color: "black",
                                justifyContent: "space-evenly",
                                height: "120px",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              {course.COURSE_DETAILS}
                            </MDBCardText>
                            <MDBRow>
                              <MDBCol size="4">
                                <a
                                  href="#!"
                                  onClick={() =>
                                    navigateTo(course, "/CoursePreview")
                                  }
                                >
                                  <MDBBtn
                                    style={{
                                      backgroundColor: "#6255A5",
                                      borderRadius: "5px",

                                      letterSpacing: "1.5px",
                                    }}
                                    className="float-start"
                                    size="sm"
                                  >
                                    Preview
                                  </MDBBtn>
                                </a>
                              </MDBCol>
                              <MDBCol size="4">
                                <div
                                  style={{
                                    paddingLeft: "1px",
                                  }}
                                >
                                  <MDBBadge
                                    className="text-dark "
                                    color="light"
                                  >
                                    {course.COURSE_TYPE === 1 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <MDBIcon
                                          fas
                                          size="lg"
                                          icon="tags"
                                          color="blue"
                                          style={{ paddingRight: "5px" }}
                                        />
                                        <h6
                                          style={{
                                            textAlign: "right",
                                            color: "blue",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              color: "blue",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            <s style={{ fontSize: "10px" }}>
                                              ₹{course.ACTUAL_PRICE}
                                            </s>
                                          </span>{" "}
                                          ₹{" "}
                                          {Math.round(
                                            course.ACTUAL_PRICE -
                                              (course.ACTUAL_PRICE *
                                                course.DISCOUNT_PERCENTAGE) /
                                                100
                                          )}
                                        </h6>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <MDBIcon
                                          fas
                                          icon="tags"
                                          size="lg"
                                          style={{
                                            paddingRight: "2px",
                                            color: "GREEN",
                                          }}
                                        />
                                        <h6
                                          style={{
                                            textAlign: "right",
                                            color: "GREEN",
                                          }}
                                        >
                                          FREE
                                        </h6>
                                      </div>
                                    )}
                                  </MDBBadge>
                                </div>
                              </MDBCol>
                              <MDBCol size="4">
                                <a
                                  // href={`/Description?courseId=${list.COURSE_ID}`}
                                  href="#!"
                                  onClick={() => {
                                    enrollPaidCourse(course);
                                  }}
                                >
                                  <MDBBtn
                                    className="float-end"
                                    type="button"
                                    size="sm"
                                    style={{
                                      backgroundColor: "#6255A5",
                                      Width: "20px",
                                      letterSpacing: "1.5px",
                                    }}
                                  >
                                    Enroll{" "}
                                  </MDBBtn>
                                </a>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                ))}
            </Carousel>
          </MDBRow>
        </div>
      </div>
    </>
  );
}
