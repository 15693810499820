import React, { useRef } from "react";
import Navbar from "./navbar.js";
import "./Course.css";
import "./lesson.css";
import Image from "../../assets/Background.png";
import Axios from "axios";
import { useState, useEffect } from "react";
import PDFViewer from "pdf-viewer-reactjs";
import { useLottie } from "lottie-react";
import { useLocation } from "react-router-dom";
import animationData from "../Lotties/SuccessLottie.json";
import coins from "../Lotties/coins.json";
import thumbsup from "../Lotties/cheer-up.json";
import Quiz from "./Quiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./footer.js";
import * as pdfjsLib from "pdfjs-dist";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBFooter,
  MDBInputGroup,
  MDBDropdownDivider,
} from "mdb-react-ui-kit";

toast.configure();
function Lessons(props) {
  const location = useLocation();
  const [CourseContent, _setCourseContent] = useState([]);

  const [percentage, setPercentage] = useState();

  const [Coins, setCoins] = useState(false);

  const [questions, setquestions] = useState();
  const [quizQuest, _setQuizQuestions] = useState();
  const [showSuccessLottie, _setShowSuccessLottie] = useState(false);
  const [quizCompleted, _setQuizCompleted] = useState();
  const lottieRef = React.useRef(showSuccessLottie);

  const quizComplRef = React.useRef(quizCompleted);
  const quizQuestRef = React.useRef(quizQuest); //CourseContent
  const CourseContentRef = React.useRef(CourseContent); //
  const UserId = sessionStorage.getItem("userid");
  var divVideoPlayer = document.getElementById("videoPlayer");
  let course_ID = sessionStorage.getItem("courseId");
  let course_Title = sessionStorage.getItem("courseTitle");
  //console.log(CourseContent)
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  //console.log(CourseContent.WATCH_COUNT)
  const Coin = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: thumbsup,

      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const notify = () => {
    toast.success(
      <div>
        <Coin />
        {"You have successfully completed the topic"}
      </div>,
      {
        toastId: "success1",
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
    setTimeout(navigate, 5000);
    // props.history.replace("/Course");
  };
  const navigate = () => {
    props.history.replace("/Course", (props = { Coins }));
  };

  const renderContent = () => {
    if (CourseContent.CONTENT_TYPE === 4) {
      return (
        <div
          id="pdfViewer"
          style={{
            height: "440px",
            width: "95vw",
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <iframe
            src={CourseContent.CONTENT_VIDEO}
            width="1500px"
            height="470px"
          />
          {/* <iframe src="https://us04web.zoom.us/j/71470625414?pwd=hZDx0cRu62qXbcITTCBb4ta1y4tsbJ.1" width="1500px" height="440px" /> */}
        </div>
      );
    } else if (CourseContent.CONTENT_TYPE === 7) {
      return (
        <div
          id="pdfViewer"
          style={{
            height: "440px",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          {console.log(CourseContent.CONTENT_VIDEO, "pdf")}
          <iframe
            src={CourseContent.CONTENT_VIDEO}
            width="1500px"
            height="470px"
          />
        </div>
      );
    } else if (CourseContent.CONTENT_TYPE === 2) {
      return (
        <div
          class="iframe-wrapper"
          id="videoViewer"
          style={{
            overflowY: "hidden",
            height: "440px",
            // width: "56vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            position: "relative",
          }}
        >
          {CourseContent.CONTENT_VIDEO ? (
            <iframe
              style={{ padding: 0, width: "680px !important" }}
              src={CourseContent.CONTENT_VIDEO}
              width="1500px"
              height="470px"
              allowfullscreen="true"
              allow="fullscreen"
              frameBorder="0"
              className="iframe"
            />
          ) : (
            <div
              style={{
                backgroundColor: "black", // Black background
                height: "440px",
                width: "56vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "'Poppins', sans-serif", // Font family Poppins
                color: "white", // White text
              }}
            >
              <p style={{ textAlign: "center", fontSize: "18px" }}>
                Video will be available soon!
              </p>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const MyPDFViewer = ({ url }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      const loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise
        .then((pdf) => {
          pdf.getPage(1).then((page) => {
            const scale = 1.5;
            const viewport = page.getViewport({ scale });

            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          });
        })
        .catch((error) => console.error(error));
    }, [url]);

    return <canvas ref={canvasRef} style={{ width: "100%", height: "90%" }} />;
  };

  const setCourseContent = (data) => {
    CourseContentRef.current = data;
    _setCourseContent(data);
  };
  const setQuizCompleted = (data) => {
    quizComplRef.current = data;
    _setQuizCompleted(data);
  };

  const setShowSuccessLottie = (isVisible) => {
    let lDiv = document.getElementById("succ-lot");

    if (isVisible) {
      lDiv.style.zIndex = "10";
    } else {
      lDiv.style.zIndex = "-1";
    }
    lottieRef.current = isVisible;
    _setShowSuccessLottie(isVisible);
  };

  useEffect(() => {
    let cObj = sessionStorage.getItem("contentObj");
    // console.log(cObj,">>>>>>>>>>>>>>>>onk")

    let obj = JSON.parse(cObj);
    // console.log(obj,">>>>>>>>>>>>>>>>")
    setPercentage(0);
    setShowSuccessLottie(false);
    //document.getElementById("videoPlayer");
    Axios.get(`/user/Content/${obj}/${UserId}`).then((response) => {
      // console.log(response.data.recordset[0]);
      let contentEle = response.data.recordset[0];
      contentEle.PERCENTAGE_VIEWED =
        (contentEle.CURRENT_PROGRESS / contentEle.CONTENT_VIDEO_DURATION) * 100;
      if (contentEle.CONTENT_PROGRESS_STATUS === 3) {
        contentEle.CURRENT_PROGRESS = 0;
        contentEle.PERCENTAGE_VIEWED = 100;
      }
      setCourseContent(contentEle);
      //if (contentEle.IS_FREE_PREVIEW === 1) {
      setShowSuccessLottie(false);
      if (contentEle.CONTENT_TYPE === 1) {
        // document.getElementById('quizViewer').style.display = 'none'
        document.getElementById("videoCart").style.display = "block";
        if (document.getElementById("videoCart").onplay)
          document
            .getElementById("videoCart")
            .removeEventListener("play", started);
        if (document.getElementById("videoCart").onpause)
          document
            .getElementById("videoCart")
            .removeEventListener("pause", paused);
        if (document.getElementById("videoCart").onended)
          document
            .getElementById("videoCart")
            .removeEventListener("ended", finished);

        // if(vidEle)
        // {
        // let filepath = `/static/videos/${contentEle.CONTENT_VIDEO}#t=${contentEle.CURRENT_PROGRESS}`

        let filepath = contentEle.CONTENT_VIDEO;
        // contentEle.CONTENT_VIDEO.substring(0, 5) === 'https'
        //   ? `${contentEle.CONTENT_VIDEO}#t=${contentEle.CURRENT_PROGRESS}`
        //   : `/static/videos/${contentEle.CONTENT_VIDEO}#t=${contentEle.CURRENT_PROGRESS}`

        document.getElementById("videoCart").setAttribute("src", filepath);
        document
          .getElementById("videoCart")
          .setAttribute("preload", "metadata");
        document
          .getElementById("videoCart")
          .addEventListener("play", started, { passive: true });
        document
          .getElementById("videoCart")
          .addEventListener("pause", paused, { passive: true });
        document
          .getElementById("videoCart")
          .addEventListener("ended", finished, { passive: true });
        document.getElementById("videoCart").style.display = "block";
        document.getElementById("videoCart").contentId = contentEle.CONTENT_ID;
        document.getElementById("videoCart").parentId = contentEle.PARENT_ID;
        document
          .getElementById("videoCart")
          .addEventListener("contextmenu", (event) => {
            event.preventDefault();
          });
        document.getElementById("videoCart").load();
        //vidEle.play()
        //}
      }
      // else if (contentEle.CONTENT_TYPE === 2) {
      //   document.getElementById("videoCart").setAttribute("src", "");
      //   divVideoPlayer.style.display = "none";
      //   document.getElementById("quizViewer").style.display = "none";
      // }
      else if (contentEle.CONTENT_TYPE === 3) {
        //divVideoPlayer.style.display = "none";
        document.getElementById("videoCart").style.display = "none";
        document.getElementById("quizViewer").style.display = "block";
        // setQuizQuestions(questions)
        document.getElementById("videoCart").setAttribute("src", "");
      }
    });
  }, []);

  const paused = (event) => {
    if (event.target.contentId) {
      //console.log(`paused--contentId- ${event.target.contentId}`);

      if (event.target.currentTime > 2.0) {
        UpdateProgress(
          UserId,
          event.target.contentId,
          2,
          event.target.currentTime,
          event.target.duration,
          event.target.parentId
        );
      } else if (event.target.currentTime < 2.0) {
        UpdateProgress(
          UserId,
          event.target.contentId,
          1,
          event.target.currentTime,
          event.target.duration,
          event.target.parentId
        );
      }
    }
    //document.getElementById("videoCart").style.display = "block";
  };
  const started = (event) => {
    if (event.target.contentId) {
      //console.log(`started--contentId- ${event.target.contentId}`);
    }
  };
  const finished = (event) => {
    setShowSuccessLottie(true);
    //console.log('notify')
    notify();
    <ToastContainer />;
    //}
    //toast.success("You have successfully completed the topic", { autoClose: 4000 });

    if (event.target.contentId) {
      if (event.target.currentTime === event.target.duration) {
        //console.log("end");
        UpdateProgress(
          UserId,
          event.target.contentId,
          3,
          event.target.currentTime,
          event.target.duration,
          event.target.parentId
        );
        CourseContent.WATCH_COUNT = CourseContent.WATCH_COUNT + 1;
      }
    }
    setTimeout(() => {
      setShowSuccessLottie(false);
    }, 2000);
  };
  const UpdateProgress = (
    userId,
    contentId,
    videoStatus,
    currentProgress,
    duration,
    parentId
  ) => {
    // //console.log(
    //   `userId- ${userId}  contentId- ${contentId}  videoStatus-  ${videoStatus}  currentProgress${currentProgress}`,
    // )
    Axios.post("/user/UpdateUserProgress", {
      USER_ID: userId,
      CONTENT_ID: contentId,
      VIDEO_STATUS: videoStatus,
      CURRENT_PROGRESS: currentProgress,
      PARENT_ID: parentId,
    }).then((response) => {
      if (response.status === 200) {
      } else {
        //console.log('Update progress error')
      }
    });
    let objCC = CourseContent;
    let coins = 0;
    if (videoStatus === 3) {
      if (
        CourseContent.WATCH_COUNT === null ||
        CourseContent.WATCH_COUNT === undefined ||
        CourseContent.WATCH_COUNT === 0
      ) {
        coins = CourseContent.POINTS_TO_BE_AWARDED;
        setCoins(true);
      }
    }
    if (objCC !== null && objCC !== undefined) {
      if (videoStatus === 3) {
        objCC.CURRENT_PROGRESS = 0;
        CourseContent.CURRENT_PROGRESS = 0;
      } else {
        objCC.CURRENT_PROGRESS = currentProgress;
        CourseContent.CURRENT_PROGRESS = currentProgress;
      }
      CourseContent.CONTENT_PROGRESS_STATUS = videoStatus;
      if (
        CourseContent.WATCH_COUNT === null ||
        CourseContent.WATCH_COUNT === undefined ||
        CourseContent.WATCH_COUNT === 0
      ) {
        CourseContent.SCORE = coins;
        objCC.SCORE = coins;
      }
      CourseContent.PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
      objCC.CONTENT_PROGRESS_STATUS = videoStatus;

      objCC.PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
      //setCourseContent(objCC);
    }
  };
  const LessonCompletedLottie = () => {
    //animationData: success,
    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const { View } = useLottie(options);
    return View;
  };

  const handleCallback = (quizComplete) => {
    setQuizCompleted(true);
    UpdateProgress(
      UserId,
      CourseContent.CONTENT_ID,
      3,
      0,
      CourseContent.PARENT_ID
    );
  };

  return (
    <>
      <div id="succ-lot" className="lottie-wrapper">
        {showSuccessLottie && <LessonCompletedLottie />}
      </div>
      <div
        style={{
          fontFamily: "Playfair Display",
        }}
      >
        {/* <div> */}
        <Navbar props={Coins} />
        {/* </div> */}

        {/* <div style={{ margin: '15px' }}> */}
        {/* <div> */}
        <MDBRow
          style={{
            margin: "15px",
            display: "flex",
            justifyContent: "center",
            width: "90vw",
          }}
        >
          <MDBCol
            size="9"
            style={{ display: "flex", alignItems: "center", width: "65%" }}
          >
            <a href={`/Course`}>
              <MDBIcon
                size="lg"
                className="float-start"
                style={{
                  color: "#9937F1",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  paddingRight: "10px",
                }}
                icon="arrow-circle-left"
              />
            </a>
            <p
              style={{
                color: "#9937F1",
                fontSize: "1.3rem",
                fontWeight: "bold",
                paddingLeft: "10px",
                paddingTop: "5px",
              }}
            >
              {course_Title}
            </p>
          </MDBCol>
          <MDBCol
            size="3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "15%",
            }}
          >
            {/* { {statu} === 3 ? ( <></>) : (
                      <div>
                        <span style={{ fontSize: "18px", color: "gray" }}> { Math.round(CourseContent.PERCENTAGE_VIEWED) > 0 &&  CourseContent.PERCENTAGE_VIEWED !== undefined && Math.round(CourseContent.PERCENTAGE_VIEWED) < 100
                            ? Math.round(CourseContent.PERCENTAGE_VIEWED).toString() +
                              "%"
                            : ""}
                        </span>
                      </div>
                    )}  */}
            {/* {CourseContent.WATCH_COUNT > 0 &&
            CourseContent.WATCH_COUNT !== undefined ? (
              <MDBBadge className=" float-end" color="violet">
                <MDBIcon
                  fas
                  icon="eye"
                  size="lg"
                  style={{
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                />
                <span style={{ fontSize: "15px", color: "gray" }}>
                  {CourseContent.WATCH_COUNT} Views
                </span>
              </MDBBadge>
            ) : (
              <span></span>
            )}
            {CourseContent.SCORE > 0 && CourseContent.SCORE !== undefined ? (
              <MDBBadge className=" float-end" color="violet">
                <MDBIcon
                  fas
                  icon="coins"
                  size="lg"
                  style={{
                    color: "orange",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                />
                <span style={{ fontSize: "15px", color: "gray" }}>
                  {CourseContent.SCORE} Coins Earned
                </span>
              </MDBBadge>
            ) : (
              <span></span>
            )} */}
          </MDBCol>
          <MDBCol style={{ width: "15%" }}>
            <button
              onClick={() =>
                UpdateProgress(
                  UserId,
                  CourseContent.CONTENT_ID,
                  3,
                  0,
                  0,
                  CourseContent.PARENT_ID
                )
              }
              style={{
                marginLeft: "3vw",
                paddingLeft: "20px",
                backgroundColor: "#283EF4",
                color: "white",
                height: "40px",
                width: "140px",
                fontSize: "12px",
                fontWeight: "bold",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
                display:
                  CourseContent.CONTENT_TYPE === 4 ||
                  CourseContent.CONTENT_TYPE === 7 ||
                  CourseContent.CONTENT_TYPE === 2
                    ? "block"
                    : "none",
              }}
            >
              {CourseContent.CONTENT_TYPE === 7
                ? "Close Meeting"
                : "Mark as complete"}
            </button>
          </MDBCol>
        </MDBRow>
        {/* </div> */}
        {/* </div> */}
        {/* <div> */}
        <MDBRow style={{ height: "450px" }} className="gradient">
          <MDBCol
            size="5"
            style={{
              paddingLeft: "70px",
              paddingTop: "30px",
              color: "white",
              height: "381px",
              display:
                CourseContent.CONTENT_TYPE === 4 ||
                CourseContent.CONTENT_TYPE === 7
                  ? "none"
                  : "block",
            }}
          >
            <h3
              style={{
                color: "#283EF4",
                fontSize: "15px",
                paddingBottom: "25px",
              }}
            >
              {" "}
              {CourseContent.CONTENT_TITLE}
            </h3>
            <h5 style={{ color: "#454545", fontSize: "14px" }}>
              {CourseContent.CONTENT_SHORT_DESCRIPTION}
            </h5>
          </MDBCol>
          {/* {if(CourseContent.co)} */}
          <MDBCol
            className="float-end"
            id="videoPlayer"
            size="7"
            style={{
              height: "450px", //height: '381px',
              width: "56vw",
              display: "block",
            }}
          >
            {CourseContent.CONTENT_TYPE === 1 ? (
              <video
                id="videoCart"
                controls
                autoPlay
                src={CourseContent.CONTENT_VIDEO}
                // src={
                //   CourseContent.CONTENT_VIDEO !== null &&
                //   CourseContent.CONTENT_VIDEO !== undefined &&
                //   CourseContent.CONTENT_VIDEO.substring(0, 5) === 'https'
                //     ? CourseContent.CONTENT_VIDEO
                //     : `/static/videos/${CourseContent.CONTENT_VIDEO}`
                // }
                controlsList="nodownload"
                style={{
                  height: "450px",
                  display: "none",
                  zIndex: "999",
                  right: "0",
                  // width: "100%",
                  // verticalAlign: "middle",
                  // padding: "1px",
                }}
              ></video>
            ) : (
              renderContent()
            )}
            {/* <MDBRow id="quizViewer"> */}
            {/* <div
                  id="quizViewer"
                  style={{
                    padding: '10px',
                    display: "none",
                  }}
                > */}
            {/* <Quiz 
                    id="quizComp"
                    key={quizQuest}
                    questions={questions}
                    parentCallback={handleCallback} style={{
                      height: '100%',
                      width: '100%',
                      display: 'block',
                    }}
                  /> */}
            {/* </div> */}
            {/* </MDBRow> */}
            {/* )} */}
          </MDBCol>
        </MDBRow>
        {/* </div> */}
      </div>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader style={{ backgroundColor: "orange" }}>
              <MDBModalTitle>Padho Bhado!!</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="warning"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Please Enroll to View this topic!!</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                style={{
                  backgroundColor: "#FE3D0B",
                  Width: "20px",
                  letterSpacing: "1.5px",
                }}
                onClick={toggleShow}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* <MDBFooter className="fixed">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position:'absolute',
            left:'0',
            bottom:'0',
            right:'0'
          }}
        >
          <MDBCol
            size="10"
            className="text-center p-3"
            style={{
              fontSize: '10px',
              // backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            © 2022 Copyright:
            <a className="text-black" href="http://lmsdev.padhobadho.com/">
              PadhoBadho
            </a>
          </MDBCol>
          <MDBCol
            size="2"
            //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
          >
            <section
              className="float-end "
              style={{
                paddingTop: '5px',
                paddingRight: '10px',
                fontSize: '10px',
              }}
            >
              <a className="  btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="facebook-f" />
              </a>

              <a
                className=" btn-floating text-black "
                href="#!"
                role="button"
                size="10px"
              >
                <MDBIcon fab icon="instagram" />
              </a>

              <a className=" btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="linkedin-in" />
              </a>

            </section>
          </MDBCol>
        </div>
        <MDBDropdownDivider></MDBDropdownDivider>
      </MDBFooter> */}
    </>
  );
}

export default Lessons;
